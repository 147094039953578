import { ISubmission } from '@/interfaces/ISubmission'
import React, { useMemo } from 'react'
import VotingHandler from './VotingHandler'
import CommentCounter from './CommentCounter'
import QuarterBadge from './QuarterBadge'
import Category from './Category'
import Status from './Status'
import { KeyedMutator } from 'swr'
import Link from '@/components/CustomLink'
import TagComponent from './Tag'
import { useUser } from '@/data/user'
import { useCurrentOrganization } from '@/data/organization'
import { can } from '@/lib/acl'
import { IRoadmapDocument } from '@/interfaces/IOrganization'
import { mongooseQueryStringToObject } from '@/lib/utils'
import { sanitizeHTML } from '@/lib/contentSanitizer'

const RoadmapElement: React.FC<{
  roadmapItem: any
  entry: ISubmission
  setActiveSubmissionId: Function
  setMainPostView: Function
  mutateSubmissions: KeyedMutator<any>
  rawSubmissionData: any
  enableRedirection?: boolean
  setUrl?: any
}> = React.memo(
  ({
    entry,
    setActiveSubmissionId,
    setMainPostView,
    mutateSubmissions,
    rawSubmissionData,
    enableRedirection,
    setUrl,
    roadmapItem,
  }) => {
    const roadmapFilters = useMemo(() => {
      return mongooseQueryStringToObject(roadmapItem?.filter || '')
    }, [roadmapItem?.filter])

    const defaultFilters = ['sortBy', 'inReview']

    // Check if moved roadmap has one status filter
    const shouldHideFilter = (filterType: string) => {
      return roadmapFilters?.every(
        (filter) => filter.type === filterType || defaultFilters.includes(filter.type)
      )
    }

    const hideStatus = shouldHideFilter('s')
    const hideBoard = shouldHideFilter('b')
    const hideDate = shouldHideFilter('e')

    return (
      // <motion.div className="overflow-hidden" initial={{ height: 0 }} animate={{ height: 'auto' }}>
      // </motion.div>
      <div className="relative min-w-0 p-3 mb-3 ml-1 mr-2 bg-white border rounded-lg shadow-sm dark:ring-1 dark:ring-black/10 group hover:border-gray-100 hover:bg-gray-50 dark:shadow dark:bg-secondary/70 dark:hover:bg-secondary dark:border-border main-transition border-gray-100/60 ">
        {enableRedirection ? (
          <Link
            scroll={false}
            legacyBehavior
            href={`?/p/${entry.slug}`}
            as={`/p/${entry.slug}`}
            shallow={true}
          >
            <a
              className="absolute inset-0 rounded-lg"
              onClick={() => {
                setActiveSubmissionId(entry.id)
                setMainPostView(true)
                setUrl(`/p/${entry.slug}`)
              }}
              aria-hidden="true"
            ></a>
          </Link>
        ) : (
          <button
            className={'unstyled-button absolute inset-0 rounded-lg'}
            onClick={() => {
              setActiveSubmissionId(entry.id)
              setMainPostView(true)
            }}
          ></button>
        )}
        <div className="">
          <a className="overflow-hidden text-sm font-semibold text-gray-500 line-clamp-3 content dark:text-gray-100 group-hover:underline decoration-gray-100 dark:decoration-gray-500">
            {entry?.highlightedTitle ? (
              <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(entry.highlightedTitle) }} />
            ) : (
              entry.title
            )}
          </a>

          <div className="flex flex-wrap items-end justify-between h-full mt-1 gap-y-1 gap-x-2">
            {!hideStatus && (
              <div className="z-20 inline-block">
                <Status
                  status={entry.postStatus}
                  dashboard={true}
                  small={true}
                  postId={entry.id}
                  mutateSubmissions={mutateSubmissions}
                  rawSubmissionData={rawSubmissionData}
                  submission={entry}
                />
              </div>
            )}
            <div className="flex flex-wrap gap-2 mt-1">
              {!hideBoard && (
                <div className="z-20 inline-block">
                  <Category
                    mutateSubmissions={mutateSubmissions}
                    small={true}
                    postId={entry.id}
                    rawSubmissionData={rawSubmissionData}
                    dash={true}
                    category={entry.postCategory}
                  />
                </div>
              )}
              {entry?.eta && !hideDate && (
                <div className="z-20">
                  <QuarterBadge date={entry?.eta} />
                </div>
              )}
              {entry.postTags?.length !== 0 && (
                <div className="z-20 inline-flex items-center">
                  <TagComponent
                    dash={true}
                    mutateSubmissions={mutateSubmissions}
                    postId={entry.id}
                    rawSubmissionData={rawSubmissionData}
                    tags={entry.postTags}
                    small={true}
                  />
                </div>
              )}
              {entry?.commentCount ? (
                <div className="z-20">
                  <CommentCounter count={entry.commentCount} />
                </div>
              ) : null}
            </div>
            <div className="z-10 flex flex-col mt-1 ml-auto">
              <VotingHandler
                small={true}
                rawSubmissionData={rawSubmissionData}
                submission={entry}
                mutateSubmissions={mutateSubmissions}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

RoadmapElement.displayName = 'RoadmapElement'

export default RoadmapElement
